import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import StaticPageTwo from '../../Components/StaticPageTwo/StaticPageTwo';

const StaticTwo=()=>{

    return(
        <React.Fragment>
            <div className="wrapper">
                <Header/>
                <div className="static-page">
                     <StaticPageTwo />
                </div>
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default StaticTwo;