import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col,Breadcrumb } from 'react-bootstrap';
import './Breadcrumbs.scss';

const Breadcrumbs = () => {
	return (
		<div className="breadcrumb-wrap">
           <Container>
             <Row>
               <Col md='12'>
                  <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">contact</Breadcrumb.Item>
                    <Breadcrumb.Item active>our people</Breadcrumb.Item>
                  </Breadcrumb>
               </Col>
             </Row>
           </Container>
           </div>
	)
}

export default Breadcrumbs
