import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
//import ScrollAnimation from "react-animate-on-scroll"
import "./SubscribeForm.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import Subscribe from "../../../Components/forms/subscribe"
import ReactMarkdown from "react-markdown"
const SubscribeForm = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalConfig(publicationState: LIVE) {
          Newsletter_Title
          Newsletter_content
        }
      }
    }
  `)
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="subscribe-form"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="form-content">
                  {/* {data.glstrapi?.globalConfig?.Newsletter_Title && (
                    <motion.div variants={fadeInFromLeft}>
                      <ReactMarkdown
                        source={data.glstrapi?.globalConfig?.Newsletter_Title}
                        allowDangerousHtml
                      />
                    </motion.div>
                  )} */}
                  <motion.div variants={fadeInFromLeft}>
                    {props.type === "Research" ? (
                      <h2>
                        <span class="highlight-text">Subscribe</span>
                      </h2>
                    ) : (
                      <h2>
                        <span class="highlight-text">Subscribe</span> for{" "}
                        {props.type === "News"
                          ? "News & Insights"
                          : props.type === "Express"
                          ? "express updates"
                          : "insight & opinion updates"}{" "}
                        
                      </h2>
                    )}
                  </motion.div>
                  {props.type === "Research" ? (
                    <p>Subscribe to receive DNG Research & Analysis Updates</p>
                  ) : (
                    data.glstrapi?.globalConfig?.Newsletter_content && (
                      <motion.div variants={fadeInFromLeft}>
                        <ReactMarkdown
                          source={
                            data.glstrapi?.globalConfig?.Newsletter_content
                          }
                          allowDangerousHtml
                        />
                      </motion.div>
                    )
                  )}

                  {/* <Form>
                    <motion.div
                      variants={contentItem}
                      className="subscribe-block"
                    >
                      <Form.Group>
                        <Form.Control type="text" placeholder="Name " />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          type="email"
                          placeholder="Email Address "
                        />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                        Register
                      </Button>
                    </motion.div>
                  </Form> */}
                  <Subscribe type={props.type} />
                </div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default SubscribeForm
