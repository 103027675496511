import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import StaticBanner from "./StaticBanner/StaticBanner";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import NavBar from "./NavBar/NavBar";
import FilterBar from "./FilterBar/FilterBar";
import PeopleList from "./PeopleList/PeopleList";
import OurAdvantage from "./OurAdvantage/OurAdvantage";
import AnalysisResearch from "./AnalysisResearch/AnalysisResearch";
import SubscribeForm from "./SubscribeForm/SubscribeForm";
import CurrentVacancies from "./CurrentVacancies/CurrentVacancies";
import { useWindowSize } from '../../hooks/window-size'
import './StaticPageTwo.scss';

const StaticPageTwo = () => {
	const [windowWidth] = useWindowSize();
	return (
		<React.Fragment>
		<Breadcrumbs/>
		<StaticBanner/>
		<NavBar/>
		<FilterBar/>
		<PeopleList/>
		<OurAdvantage/>
		<AnalysisResearch/>
		<SubscribeForm/>
		<CurrentVacancies/>
		</React.Fragment>
	)
}

export default StaticPageTwo
