import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import './NavBar.scss';

const NavBar = () => {
	return (
		<div className="nav-bar">
			<Container>
				<Row className="align-items-xl-center">
					<Col lg="8" xl="6">
						<div className="link-wrap">
							<ul>
								<li>
									<Link href="#">Details</Link>
								</li>
								<li>
									<Link href="#">Gallery</Link>
								</li>
								<li>
									<Link href="#">Availability <span className="badge-text">7</span></Link>
								</li>
								<li>
									<Link href="#">Brochure</Link>
								</li>
								<li>
									<Link href="#">Siteplan</Link>
								</li>
								<li>
									<Link href="#">Map</Link>
								</li>
							</ul>
						</div>
					</Col>
					<Col lg="8" xl="6" className="d-none d-xl-block justify-content-lg-end">
					<div className="filter-share">
						<ul>
							<li>
								<Link href="#">
									<i className="icon-heart-gray"></i>
									Save
								</Link>
							</li>
							<li>
								<Link href="#">
									<i className="icon-share-gray"></i>
									share
								</Link>
							</li>
							<li>
								<Link href="tel:014909000" className="tel">
									<i className="icon-phone-gray"></i>
									01 490 9000
								</Link>
							</li>
							<li>
							<Link href="#" className="btn">enquire now</Link>
							</li>
						</ul>
					</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default NavBar
